import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Uploadcare EU-U.S. and Swiss-U.S. Privacy Shield Notice',
  description: 'Uploadcare has certified with the EU-U.S. and Swiss-U.S. Privacy Shield with respect to the personal data we receive and process on behalf of our customers through our file handling mechanisms.',
  noindex: true
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Effective: September 18, 2017`}</p>
    <p>{`Uploadcare, Inc. (“We” or “Our”) has certified with the `}<a parentName="p" {...{
        "href": "https://www.privacyshield.gov/welcome"
      }}>{`EU-U.S. and Swiss-U.S.
Privacy Shield`}</a>{` with respect to the personal data we
receive and process on behalf of our customers through our file handling
mechanisms (the “Services”). Uploadcare certifies that it adheres to the Privacy
Shield Principles of notice, choice, onward transfer, security, data integrity,
access, and enforcement for personal data submitted by our customers in
participating European countries through the Services, and our Privacy Shield
certification will be available `}<a parentName="p" {...{
        "href": "https://www.privacyshield.gov/participant?id=a2zt0000000PCByAAO&status=Active"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "data-processed"
    }}><a parentName="h2" {...{
        "href": "#data-processed"
      }}>{`Data Processed`}</a></h2>
    <p>{`We provide the Services so that our customers don’t have to build their own
solutions for handling files (uploads, processing, storage, and delivery) for
web and mobile apps. In providing these Services, we process data our customers
submit to the Services or instruct us to process on their behalves in connection
with the Services (“Customer Data”).`}</p>
    <h2 {...{
      "id": "purposes-of-data-processing"
    }}><a parentName="h2" {...{
        "href": "#purposes-of-data-processing"
      }}>{`Purposes of Data Processing`}</a></h2>
    <p>{`In order to provide the Services, we capture and store some information about
you, and the users uploading material to the service:`}</p>
    <ol>
      <li parentName="ol">{`IP Addresses`}</li>
      <li parentName="ol">{`Request Headers`}</li>
      <li parentName="ol">{`Data entered on our website`}</li>
      <li parentName="ol">{`Data submitted to our API`}</li>
    </ol>
    <p>{`We don’t use data from uploaded files for anything, except for:`}</p>
    <ol>
      <li parentName="ol">{`Detecting its MIME-type`}</li>
      <li parentName="ol">{`Generating preview on request`}</li>
      <li parentName="ol">{`Converting or modifying the files on request from Account owner of that file`}</li>
    </ol>
    <p>{`All data stored by us is only used for internal processing, and never sold or
otherwise given away.`}</p>
    <h2 {...{
      "id": "third-parties-with-whom-we-may-share-customer-data"
    }}><a parentName="h2" {...{
        "href": "#third-parties-with-whom-we-may-share-customer-data"
      }}>{`Third Parties With Whom We May Share Customer Data`}</a></h2>
    <p>{`We use a limited number of third party providers to assist us in providing the
Services to our customers. As of the date hereof, these third party providers
perform technical operations such as database monitoring, data storage and
delivery, hosting services, and customer support software tools. These third
parties may access, process or store personal data in the course of providing
these services, but based on our instructions only.`}</p>
    <p>{`If we receive personal data subject to our certification under the Privacy
Shield and then transfer it to a third-party service provider acting as an agent
on our behalf, we have certain liability under the Privacy Shield if both (i)
the agent processes the personal data in a manner inconsistent with the Privacy
Shield and (ii) we are responsible for the event giving rise to the damage.
Questions or Complaints If you are a resident of a European country
participating in the Privacy Shield and you believe we maintain your personal
data within the scope of this Privacy Shield certification, you may direct any
questions or complaints concerning our Privacy Shield compliance to
`}<a parentName="p" {...{
        "href": "mailto:privacyshield@uploadcare.com"
      }}>{`privacyshield@uploadcare.com`}</a>{` or at our mailing
address:`}</p>
    <p>{`Uploadcare, Inc.`}<br />{` 2711 Centerville Road, Suite 400,`}<br />{` City of Wilmington,
County of New Castle, DE 19808,`}<br />{` USA.`}</p>
    <p>{`We will work with you to resolve your issue.`}</p>
    <h2 {...{
      "id": "dispute-resolution"
    }}><a parentName="h2" {...{
        "href": "#dispute-resolution"
      }}>{`Dispute Resolution`}</a></h2>
    <p>{`If you are a resident of a European country participating in the Privacy Shield
and you have not received timely response to your concern, or we have not
addressed your concern to your satisfaction, you may seek further assistance, at
no cost to you, from `}<a parentName="p" {...{
        "href": "http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm"
      }}>{`EU Data Protection Authorities`}</a>{` for
EU/EEA Data Subjects, and the `}<a parentName="p" {...{
        "href": "https://www.edoeb.admin.ch/edoeb/en/home/data-protection.html"
      }}>{`Swiss Federal Data Protection and Information
Commissioner`}</a>{` for Swiss Data Subjects, which are an
independent dispute resolution bodies.`}</p>
    <h2 {...{
      "id": "arbitration"
    }}><a parentName="h2" {...{
        "href": "#arbitration"
      }}>{`Arbitration`}</a></h2>
    <p>{`You may also be able to invoke binding arbitration for unresolved complaints but
prior to initiating such arbitration, a resident of a European country
participating in the Privacy Shield must first: (1) contact us and afford us the
opportunity to resolve the issue; (2) seek assistance from `}<a parentName="p" {...{
        "href": "http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm"
      }}>{`EU Data Protection
Authorities`}</a>{` for EU/EEA Data Subjects, and the `}<a parentName="p" {...{
        "href": "https://www.edoeb.admin.ch/edoeb/en/home/data-protection.html"
      }}>{`Swiss
Federal Data Protection and Information Commissioner`}</a>{` for
Swiss Data Subjects; and (3) contact the U.S. Department of Commerce (either
directly or through a European Data Protection Authority) and afford the
Department of Commerce time to attempt to resolve the issue. If such a resident
invokes binding arbitration, each party shall be responsible for its own
attorney’s fees. Please be advised that, pursuant to the Privacy Shield, the
arbitrator(s) may only impose individual-specific, non-monetary, equitable
relief necessary to remedy any violation of the Privacy Shield Principles with
respect to the resident.`}</p>
    <h2 {...{
      "id": "us-federal-trade-commission-enforcement"
    }}><a parentName="h2" {...{
        "href": "#us-federal-trade-commission-enforcement"
      }}>{`U.S. Federal Trade Commission Enforcement`}</a></h2>
    <p>{`Our Privacy Shield compliance is subject to the investigatory and enforcement
powers of the U.S. Federal Trade Commission (FTC).`}</p>
    <h2 {...{
      "id": "right-of-access"
    }}><a parentName="h2" {...{
        "href": "#right-of-access"
      }}>{`Right of Access`}</a></h2>
    <p>{`Some international users (including those whose personal data is within the
scope of this Privacy Shield certification) have certain legal rights to access
certain personal data we hold about them and to obtain its correction, amendment
or deletion. Those users may exercise those rights through the options described
in their Dashboard and Account settings or by contacting us via
`}<a parentName="p" {...{
        "href": "mailto:privacyshield@uploadcare.com"
      }}>{`privacyshield@uploadcare.com`}</a>{`.`}</p>
    <h2 {...{
      "id": "requirement-to-disclose"
    }}><a parentName="h2" {...{
        "href": "#requirement-to-disclose"
      }}>{`Requirement to Disclose`}</a></h2>
    <p>{`We may disclose personal data when we have a good faith belief that such action
is necessary to: conform to legal requirements or to respond to lawful requests
by public authorities, including to meet national security or law enforcement
requirements; or to enforce our contractual obligations.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      